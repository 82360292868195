// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classNamees given
// any value of `@grid-columns`.

.make-grid-columns() {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) { // initial
    @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-xs-@{index}, .col-sm-@{index}, .col-md-@{index}, .col-lg-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-left:  ceil((@grid-gutter-width / 2));
      padding-right: floor((@grid-gutter-width / 2));
    }
  }
  .col(1); // kickstart it
}

.float-grid-columns(@className) {
  .col(@index) { // initial
    @item: ~".col-@{className}-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general
    @item: ~".col-@{className}-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      float: left;
    }
  }
  .col(1); // kickstart it
}

.calc-grid-column(@index, @className, @type) when (@type = width) and (@index > 0) {
  .col-@{className}-@{index} {
    width: percentage((@index / @grid-columns));
  }
}
.calc-grid-column(@index, @className, @type) when (@type = push) and (@index > 0) {
  .col-@{className}-push-@{index} {
    left: percentage((@index / @grid-columns));
  }
}
.calc-grid-column(@index, @className, @type) when (@type = push) and (@index = 0) {
  .col-@{className}-push-0 {
    left: auto;
  }
}
.calc-grid-column(@index, @className, @type) when (@type = pull) and (@index > 0) {
  .col-@{className}-pull-@{index} {
    right: percentage((@index / @grid-columns));
  }
}
.calc-grid-column(@index, @className, @type) when (@type = pull) and (@index = 0) {
  .col-@{className}-pull-0 {
    right: auto;
  }
}
.calc-grid-column(@index, @className, @type) when (@type = offset) {
  .col-@{className}-offset-@{index} {
    margin-left: percentage((@index / @grid-columns));
  }
}

// Basic looping in LESS
.loop-grid-columns(@index, @className, @type) when (@index >= 0) {
  .calc-grid-column(@index, @className, @type);
  // next iteration
  .loop-grid-columns((@index - 1), @className, @type);
}

// Create grid for specific className
.make-grid(@className) {
  .float-grid-columns(@className);
  .loop-grid-columns(@grid-columns, @className, width);
  .loop-grid-columns(@grid-columns, @className, pull);
  .loop-grid-columns(@grid-columns, @className, push);
  .loop-grid-columns(@grid-columns, @className, offset);
}
